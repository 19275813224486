(function ($) {

    $(document).ready(function () {
        var windowSize = window.innerWidth

        function hideMobileMenu() {
            $('#close').hide(150);
            $('#open').show(150);
            $('.header-menu').removeClass('header-menu--open');
            $('[data-id="nos_produits"]').find('> ul').hide();
            $('.menu-item > ul').removeClass('show')
            $('.menu-item.is-open').toggleClass('is-open')
        }

        function toggleDesktopMenu(force) {
            var menuCategories = document.querySelector('#show-menu-categories')
            var closeIt = force === false || (force !== true && menuCategories.classList.contains('menu-categories--open'))

            menuCategories.classList.toggle('menu-categories--open', !closeIt)
            menuCategories.classList.toggle('menu-categories', closeIt)
        }

        /**
         * Open menu on mobile
         */
        $('.menu-actions').on('click', function () {
            $('#close').toggle(150);
            $('#open').toggle(150);
            $('.header-menu').toggleClass('header-menu--open');

            if ($('.header-menu').hasClass('header-menu--open')) {
                $('[data-id="nos_produits"]').append($('#menu-categories-responsive'));
                $('[data-id="nos_produits"]').find('#menu-categories-responsive').removeAttr('id');
            } else {
                $('.menu-item.is-open').toggleClass('is-open')
            }

            $('[data-id="nos_produits"]').find('> ul').hide();
            $('.menu-item > ul').removeClass('show')
        });

        $('.menu-item-has-children > a').on('click', function(e) {
            e.preventDefault()

            if (window.innerWidth < 1100) {
                $(this).parent().toggleClass('is-open');
            }
        })

        $('[data-id="nos_produits"] a').on('click', function (e) {
            e.preventDefault();

            if (window.innerWidth < 1100) {
                /**
                 * Mobile
                 */
                $(this).parent().toggleClass('is-open');
                $(this).parent().find('> ul').toggle();
            } else {
                /**
                 * Desktop
                 */
                toggleDesktopMenu()
            }
        })

        window.onresize = function() {
            if (window.innerWidth < 1100 && windowSize >= 1100) {
                /**
                 * Mobile
                 */
                toggleDesktopMenu(false)
            } else if (window.innerWidth >= 1100 && windowSize < 1100) {
                /**
                 * Desktop
                 */
                hideMobileMenu()
            }

            windowSize = window.innerWidth
        }

        /**
         * Pdfs
         */
        var pdfEntries = Object.entries(pdfs);

        for (var k in pdfEntries) {
            var selector = null;

            var v = pdfEntries[k][1];
            k = pdfEntries[k][0];

            switch (k) {
                case 'advertising':
                    selector = '[data-id="publicité"] > a, [data-id="publicité_de_la_semaine"] > a';
                    break;

                case 'catalog':
                    selector = '[data-id="catalogue"] > a';
                    break;
            }

            if (selector !== null) {
                document.querySelectorAll(selector).forEach(function (elem) {
                    elem.setAttribute('href', v);
                    elem.setAttribute('target', '_blank');
                });
            }
        }
    });

})(jQuery);